import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Icon from "src/components/Icon"
import Button from "src/components/Button"
import { PrimaryTitle } from "src/components/Typography"
import { products } from "src/pages/shop/index" // tmp

export default ({ open, onClose }) => {
  return (
    <Wrapper className={open ? "-open" : ""}>
      <Inner>
        <Title>Cart</Title>
        <Products>
          {products
            .slice(0, 2)
            .map(({ branch, year, month, imageUrl, price }, index) => {
              const qty = 1

              return (
                <Product key={index}>
                  <ProductImage src={imageUrl} alt="" loading="lazy" />
                  <ProductDetails>
                    <ProductYear>{year}</ProductYear>
                    <ProductNameLink to="/shop/product">
                      <ProductName>{`${branch} ${month}`}</ProductName>
                    </ProductNameLink>
                    <ProductPrice>£2.99</ProductPrice>
                    <ProductControls>
                      <ProductQuantity>
                        <ProductQuantityButton>–</ProductQuantityButton>
                        <ProductQuantityNumber
                          type="number"
                          value={qty}
                          onChange={() => {}}
                        />
                        <ProductQuantityButton>+</ProductQuantityButton>
                      </ProductQuantity>
                      <ProductRemove>Remove</ProductRemove>
                    </ProductControls>
                  </ProductDetails>
                </Product>
              )
            })}
        </Products>
        <Submit>
          <Agreement>
            <AgreementInput type="checkbox" name="agreement" required />
            <AgreementControl />
            <AgreementLabel>
              I agree with the terms and conditions
            </AgreementLabel>
          </Agreement>
          <SubmitButton label="Continue to checkout" />
        </Submit>
        <CloseButton onClick={onClose}>
          <CloseButtonIcon name="x" />
        </CloseButton>
      </Inner>
      <Overlay onClick={onClose} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  visibility: hidden;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  transition: visibility 0.25s;

  &.-open {
    visibility: visible;
  }
`

const Inner = styled.form`
  display: flex;
  flex-direction: column;
  width: 385px;
  max-width: 100vw;
  height: 100%;
  padding: 21px 20px 20px;
  position: relative;
  z-index: 1;
  background: #fff;
  transform: translateX(100%);
  transition: transform 0.25s ${p => p.theme.easing.easeOutQuart};

  ${Wrapper}.-open & {
    transform: none;
  }

  ${media.tablet} {
    padding: 21px 25px 30px;
  }
`

const Title = styled(PrimaryTitle)`
  margin-bottom: 48px;
  font-size: 28px;

  @media (max-height: 480px) {
    margin-bottom: 30px;
  }
`

const CloseButton = styled.button`
  width: 70px;
  height: 70px;
  padding: 0;
  position: absolute;
  top: 0;
  right: 10px;
`

const CloseButtonIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 26px;
  left: 26px;
`

const Products = styled.div`
  flex: 1;
  overflow: auto;
  margin-bottom: 20px;
`

const Product = styled.div`
  display: flex;
  margin-bottom: 25px;
`

const ProductImage = styled.img`
  display: block;
  align-self: center;
  width: 125px;
  margin-right: 25px;
  margin-bottom: 8px;

  @media (max-width: 370px) {
    width: 75px;
  }
`

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 13px;
`

const ProductYear = styled.h3`
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #808080;
`

const ProductNameLink = styled(Link)`
  flex: 1;
`

const ProductName = styled.h3`
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`

const ProductPrice = styled.p`
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`

const ProductControls = styled.div`
  display: flex;
`

const ProductQuantity = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 17px;
`

const ProductQuantityButton = styled.button`
  width: 30px;
  height: 30px;
  position: relative;
  border: 1px solid #dadada;
  font-size: 0;

  &::before,
  &::after {
    content: "";
    width: 10px;
    height: 2px;
    position: absolute;
    top: 14px;
    left: 9px;
    background: currentColor;
  }

  &:last-child {
    &::before {
      transform: rotate(90deg);
    }
  }
`

const ProductQuantityNumber = styled.input`
  -moz-appearance: textfield;
  width: 46px;
  height: 30px;
  border: solid #dadada;
  border-width: 1px 0;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
`

const ProductRemove = styled.button`
  margin-left: 20px;
  font-size: 14px;
`

const Submit = styled.div``

const SubmitButton = styled(Button)`
  width: 100%;
`

const Agreement = styled.label`
  display: flex;
  margin-top: -18px;
  margin-bottom: 12px;
  position: relative;
  font-size: 14px;
`

const AgreementInput = styled.input`
  width: 1px;
  height: 1px;
  position: absolute;
  top: 10px;
  left: 10px;
  background: transparent;
  pointer-events: none;

  &:checked {
    background: blue;
  }
`

const AgreementControl = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
  margin-right: 10px;
  border: 2px solid;

  &::before {
    content: "";
    visibility: hidden;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 4px;
    left: 4px;
    background: currentColor;
  }

  ${AgreementInput}:checked ~ &::before {
    visibility: visible;
  }
`

const AgreementLabel = styled.span`
  opacity: 0.9;
  font-weight: 600;
  user-select: none;
`

const Overlay = styled.div`
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  transition: 0.25s ${p => p.theme.easing.easeOutQuart};

  ${Wrapper}.-open & {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
`
