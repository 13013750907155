import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Container from "src/components/Container"
import FilterControl from "src/components/FilterControl"
import Button from "src/components/Button"
import Pagination from "src/components/Pagination"
import Cart from "src/components/Cart"
import {
  ContentHeader,
  ContentHeaderTitle,
  FilterBar,
  FilterBarGroup,
} from "src/templates/category"

export const products = [
  {
    branch: "ArtReview",
    year: "2020",
    month: "March",
    imageUrl: "https://i.imgur.com/Shwa7Gi.jpg",
    price: {
      print: 2.99,
      digital: 1.99,
    },
  },
  {
    branch: "ArtReview",
    year: "2020",
    month: "April",
    imageUrl: "https://i.imgur.com/Shwa7Gi.jpg",
    price: {
      print: 2.99,
      digital: 1.99,
    },
  },
  {
    branch: "ArtReview",
    year: "2020",
    month: "May",
    imageUrl: "https://i.imgur.com/n0va0Le.jpg",
    price: {
      print: 2.99,
      digital: 1.99,
    },
  },
  {
    branch: "ArtReview Asia",
    year: "2020",
    month: "Winter",
    imageUrl: "https://i.imgur.com/Shwa7Gi.jpg",
    price: {
      print: 2.99,
      digital: 1.99,
    },
  },
  {
    branch: "ArtReview",
    year: "2020",
    month: "June",
    imageUrl: "https://i.imgur.com/FfDenmR.jpg",
    price: {
      print: 2.99,
      digital: 1.99,
    },
  },
  {
    branch: "ArtReview Asia",
    year: "2020",
    month: "Summer",
    imageUrl: "https://i.imgur.com/Shwa7Gi.jpg",
    price: {
      print: 2.99,
      digital: 1.99,
    },
  },
]

export default () => {
  const [cartOpen, setCartOpen] = useState(false)

  return (
    <>
      <SEO title="ArtReview" />
      <Ad type="page/before_header" />
      <PageWrapper>
        <Header dark={true} />
        <ContentHeader>
          <Container>
            <ContentHeaderTitle>Shop</ContentHeaderTitle>
            <FilterBar>
              <FilterBarGroup>
                <FilterControl
                  value=""
                  title="Channel"
                  options={["ArtReview", "ArtReview Asia"]}
                />
                <FilterControl
                  value=""
                  title="Year"
                  options={["2020", "2019"]}
                />
              </FilterBarGroup>
            </FilterBar>
          </Container>
        </ContentHeader>
        <Container>
          <ProductList>
            {products.map(({ branch, year, month, imageUrl, price }, index) => (
              <Product
                itemscope
                itemtype="http://schema.org/Product"
                key={index}
              >
                <Link to="/shop/product">
                  <ProductImage
                    src={imageUrl}
                    alt=""
                    loading="lazy"
                    itemProp="image"
                  />
                </Link>
                <ProductDetails>
                  <Link to="/shop/product" itemProp="name">
                    <ProductBranch>{branch}</ProductBranch>
                    <ProductDate>{`${month} ${year}`}</ProductDate>
                  </Link>
                  <ProductBuy>
                    <ProductBuyButton label="Buy" size="small" />
                    {Object.entries(price).map((edition, index) => (
                      <ProductBuyButton
                        label={`Buy ${edition[0]} edition - £${edition[1]}`}
                        size="small"
                        itemProp="price"
                        content={edition[1]}
                        key={index}
                        onClick={() => {
                          setCartOpen(true)
                        }}
                      />
                    ))}
                    <span hidden itemProp="priceCurrency" content="GBP">
                      £
                    </span>
                  </ProductBuy>
                </ProductDetails>
              </Product>
            ))}
          </ProductList>
        </Container>
        <Pagination onPageChange={() => {}} numPages={7} pageNumber={0} />
      </PageWrapper>
      <Ad type="page/before_footer" />
      <Cart
        open={cartOpen}
        onClose={() => {
          setCartOpen(false)
        }}
      />
    </>
  )
}

const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 130px;

  ${media.tablet} {
    justify-content: flex-start;
  }
`

const Product = styled.div`
  margin-bottom: 90px;

  ${media.tablet} {
    width: calc((100% - 20px) / 3);
    margin-bottom: 128px;

    &:not(:nth-child(3n + 1)) {
      margin-left: 10px;
    }
  }

  ${media.desktop} {
    width: calc((100% - 30px) / 4);

    &:not(:nth-child(3n + 1)) {
      margin-left: 0;
    }

    &:not(:nth-child(4n + 1)) {
      margin-left: 10px;
    }
  }
`

const ProductImage = styled.img`
  display: block;
  margin-bottom: 8px;
`

const ProductDetails = styled.div`
  max-width: 225px;
  text-align: center;
`

const ProductBranch = styled.h3`
  margin-bottom: 1px;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
`

const ProductDate = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const ProductBuy = styled.div`
  display: inline-block;
  margin-top: 24px;
  position: relative;
`

const ProductBuyButton = styled(Button)`
  margin-bottom: 2px;

  &:nth-child(1) {
    ${ProductBuy}:hover & {
      visibility: hidden;
      opacity: 0;
    }
  }

  &:nth-child(n + 2) {
    width: 225px;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    ${ProductBuy}:not(:hover) & {
      visibility: hidden;
      opacity: 0;
    }
  }

  &:nth-child(n + 3) {
    top: 46px;
  }
`
